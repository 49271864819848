<script setup lang="ts">
const emit = defineEmits<{ (event: "close"): void }>();

const CUSTOM_GAMES_NO_PROGRESS = [1845, 2028, 1720];

const { data: appInitData } = useAppInitData();
const { level, rankLeague, isStatusOnEntries, isConfirmedProfile, progress, type, nextLevel } = useStatusSystem();
const { game } = useGameModal();
const { isSweepStakes, handleSwitchGameMode } = useSwitchMode();
const { open } = useFunrizeModals();
const { t } = useT();
const { exitFullscreen } = useFullscreenToggle();

const gameWithoutProgress = computed(() => CUSTOM_GAMES_NO_PROGRESS.includes(game.value?.game?.id || 0));
const isSeasonActive = computed(() => appInitData.value?.season?.isActive);

const handleOpenRewardsModal = () => {
	exitFullscreen();
	emit("close");
	dispatchGAEvent({
		event: "open_popup",
		quantity: level.value,
		form_name: "funrize_club_rewards",
		location: "gameplay"
	});
	open("LazyOModalRewardsStatusSystem");
};
const handleClick = () => {
	if (isSeasonActive.value) {
		return;
	}

	if (!rankLeague.value?.progressData && !isConfirmedProfile.value) {
		exitFullscreen();
		open("LazyOModalProfileRewards");
		return;
	}

	if (!isSweepStakes.value) {
		exitFullscreen();
		handleSwitchGameMode();
		emit("close");
	}
};
</script>
<template>
	<APopper placement="bottom-start" arrow show offset-distance="0" @click="handleClick">
		<template #content>
			<div class="text-cannes">
				<div class="info">
					<AText variant="toledo" :modifiers="['semibold']">
						<template v-if="!rankLeague">
							{{ t("Funrize Club - coming soon") }}
						</template>
						<template v-else-if="!rankLeague?.progressData">
							{{ t("Unlock Funrize Club and start getting rewards!") }}
						</template>
						<template v-else-if="gameWithoutProgress">
							{{ t("This game doesn't provide XP") }}
						</template>
						<i18n-t v-else-if="isSweepStakes" keypath="Keep playing to unlock more {key}!">
							<template #key>
								<span>{{ t("rewards") }}</span>
							</template>
						</i18n-t>
						<i18n-t
							v-else-if="!isStatusOnEntries || (!isSweepStakes && !isStatusOnEntries)"
							keypath="Level-Up Faster in {key}"
						>
							<template #key>
								<span class="text-cixi">{{ t("promotional mode") }}</span>
							</template>
						</i18n-t>
						<i18n-t v-else keypath="Keep earning XP by switching to {key}">
							<template #key>
								<span class="text-cixi">{{ t("promotional mode") }}</span>
							</template>
						</i18n-t>
					</AText>
					<AText v-if="rankLeague" variant="topeka" class="info-issue" @click.stop="handleOpenRewardsModal"> ? </AText>
				</div>
				<template v-if="rankLeague && !gameWithoutProgress">
					<template v-if="isSweepStakes || !isStatusOnEntries">
						<div class="progress-bar">
							<div class="filled-progress" :style="{ width: progress }" />
							<NuxtImg
								:src="`/nuxt-img/status-system/slides/${type}.png`"
								class="status-img"
								alt="status"
								format="webp"
								width="52"
								height="41"
							/>
							<NuxtImg
								class="gift-img"
								:src="`/nuxt-img/status-system/${rankLeague?.progressData ? 'offer@2x' : 'lock'}.png`"
								alt="lock"
								format="avif"
								width="88"
								height="88"
							/>
						</div>
						<div class="levels">
							<div>
								<AText as="div" :modifiers="['bold', 'capitalize']">{{ t(type) }}</AText>
								<AText variant="topeka" as="div">
									<i18n-t keypath="Level {key}">
										<template #key>
											<b class="text-cannes">{{ level || 1 }}</b>
										</template>
									</i18n-t>
								</AText>
							</div>
							<div>
								<AText as="div" :modifiers="['bold', 'capitalize']">
									{{ rankLeague?.progressData ? t("Reward") : t("Reward Locked") }}
								</AText>
								<AText as="div" variant="topeka">
									<i18n-t keypath="Level {key}">
										<template #key>
											<b class="text-cannes">{{ nextLevel || 2 }}</b>
										</template>
									</i18n-t>
								</AText>
							</div>
						</div>
					</template>
				</template>
				<AText class="status-system-switch" :modifiers="['link', 'underline', 'center']" as="p">
					<template v-if="!rankLeague?.progressData && !isConfirmedProfile"> {{ t("Unlock Funrize Club") }}! </template>
					<template v-else-if="!isSweepStakes">
						{{ t("Switch to Promo") }}
					</template>
				</AText>
			</div>
			<div v-if="isSeasonActive" class="freeze-status">
				<NuxtImg src="/nuxt-img/status-system/lock.png" width="32" height="32" format="webp" alt="lock" />
				<AText class="text-cannes" :modifiers="['semibold', 'center']">
					{{
						t(
							"Don't worry! Your progress in Funrize Club is frozen but will be restored once you finish Hawaii Adventure Season."
						)
					}}
				</AText>
			</div>
		</template>
	</APopper>
</template>
<style scoped lang="scss">
:deep(.popper) {
	--popper-theme-background-color: var(--cacem);
	--popper-theme-background-color-hover: var(--cacem);
	--popper-theme-padding: 16px 12px;
	--popper-theme-border-radius: 8px;
	max-width: 343px;
	width: 100%;
	transform: none !important;
	top: 66px !important;
	left: 42px !important;

	#arrow {
		left: 10px !important;
	}

	.info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.info-issue {
		cursor: pointer;
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		border-radius: 50%;
		background: var(--chimoio);
		text-align: center;
		line-height: 18px;
	}

	.progress-bar {
		position: relative;
		display: flex;
		border-radius: 50px;
		height: 10px;
		width: 95%;
		background: var(--changshu);
		margin: gutter(3) 0 gutter(1);
	}

	.filled-progress {
		border-radius: 10px;
		height: 100%;
		min-width: 10px;
		background: var(--gloucester);
		transition: width 0.3s ease;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			right: 1px;
			width: 10px;
			height: 10px;
			border-radius: 12px;
			opacity: 0.6;
			background: var(--cannes);
			filter: blur(2px);
		}
	}

	.status-img {
		position: absolute;
		left: -14px;
		top: -15px;
	}

	.gift-img {
		position: absolute;
		right: -20px;
		top: -14px;
		width: 32px;
		height: 32px;
	}

	.levels {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: gutter(2.5) 0 gutter(1.5);
	}

	.freeze-status {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--cardenas);
		backdrop-filter: blur(4px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: gutter(1);
		border-radius: 8px;
		padding: gutter(1) gutter(6.25);
		line-height: 20px;
	}
}
</style>
